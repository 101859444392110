<template>
  <b-form
    :id="id"
    class="customform"
    novalidate
    @change.capture="onChange"
    @submit="onSubmit"
    ref="customform"
  >
    <slot :change="onChange" :submit="onSubmit" :reset="onReset"/>
  </b-form>
</template>

<script>
export default {
  name: 'CustomForm',
  props: {
    id: {
      type: String,
      required: true
    },
    preventSubmit: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      controls: {}
    }
  },
  validations () {
    return {
      controls: Object.keys(this.controls)
        .reduce((controls, cKey) => Object.assign(controls, { [cKey]: { value: this.controls[cKey].validations } }), {})
    }
  },
  computed: {
    formData () {
      return {
        id: this.id,
        controls: Object.keys(this.controls).reduce((formData, cKey) => Object.assign(formData, { [cKey]: this.controls[cKey].value }), {}),
        isValid: !this.$v.$invalid,
        isDirty: this.$v.$dirty
      }
    }
  },
  methods: {
    registerControl (control) {
      if (this.$el.querySelector(`#${control.id}`)) {
        this.controls[control.id] = control
      }
    },
    touchControls () {
      Object.keys(this.controls)
        .forEach(cKey => {
          this.controls[cKey].$touch()
        })

      this.$v.$touch()
      this.$emit('customform:touch', !this.$v.$invalid)
    },
    resetControls () {
      Object.keys(this.controls)
        .forEach(cKey => {
          this.controls[cKey].$reset()
        })

      this.$v.$reset()
    },
    onChange () {
      this.$emit('customform:change', this.formData)
    },
    onSubmit (event) {
      if (this.$v.$invalid) {
        event.preventDefault()
        this.touchControls()
      } else {
        if (this.preventSubmit) event.preventDefault()
        this.$emit('customform:submit', this.formData)
      }
    },
    onReset () {
      this.resetControls()
    }
  },
  created () {
    this.$root.$on('control:ready', this.registerControl)
  }
}
</script>

<style lang="scss">
.customform {}
</style>
